<template>
	<div class="grid">
		<div class="col-12 xl:col-12">
			<div class="card mb-0">
				<Toolbar>
          <template v-slot:left>
						<Button icon="pi pi-arrow-left" class="p-button-rounded p-button-text" @click="goToMonth('prev')"/>&nbsp;
						<Calendar id="dateRange" :showIcon="true" v-model="date_range" view="month" dateFormat="MM yy" @hide="refreshTotals()"></Calendar>&nbsp;
						<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-text" @click="goToMonth('next')"/>
					</template>
        </Toolbar>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-3">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Monthly Savings Goal</span>
						<div class="text-900 font-medium text-xl">{{ formatCurrency(this.estimatedMonthlySavingsTotal) }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-compass text-orange-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{ formatCurrency(this.actualMonthlySavingsTotal) }} </span>
				<span class="text-500"> saved this month</span>
				<div class="mt-2 pt-3 md:mt-0 flex align-items-center">
					<div class="surface-300 border-round overflow-hidden w-10rem lg:w-12rem" style="height:8px">
						<div class="bg-green-500 h-full" :style="{ width: monthlySavingsPercent + '%' }"></div>
					</div>
					<span class="text-green-500 ml-3 font-medium">%{{ monthlySavingsPercent.toFixed(0) }}</span>
				</div>
			</div>
			<!-- <div class="card flex flex-column align-items-center">
				<h5 class="align-self-start">Pie Chart</h5>
				<Chart type="pie" :data="pieData" :options="pieOptions" style="width: 50%" />
			</div> -->
		</div>
		<div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-3">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Yearly Savings Goal</span>
						<div class="text-900 font-medium text-xl">{{ formatCurrency(estimatedYearlySavingsTotal) }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-money-bill text-green-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium">{{ formatCurrency(actualYearlySavingsTotal) }}</span>
				<span class="text-500"> saved this year</span>
				<div class="mt-2 pt-3 md:mt-0 flex align-items-center">
					<div class="surface-300 border-round overflow-hidden w-10rem lg:w-12rem" style="height:8px">
						<div class="bg-green-500 h-full" :style="{ width: yearlySavingsPercent + '%' }"></div>
					</div>
					<span class="text-green-500 ml-3 font-medium">%{{ yearlySavingsPercent.toFixed(0) }}</span>
				</div>
			</div>
			<div class="card">
				<h5>Income vs Expenses</h5>
				<Chart type="bar" :data="barData" :options="barOptions" />
			</div>
		</div>
	</div>
</template>

<script>
import TransactionService from '../service/TransactionService'
import CategoryService from '../service/CategoryService'

export default {
	data() {
		return {
			date_range: "",
			actualSavings: null,
			actualMonthlySavingsTotal: 0,
			estimatedMonthlySavingsTotal: 0,
			monthlySavingsPercent: 0,
			actualYearlySavingsTotal: 0,
			estimatedYearlySavingsTotal: 0,
			yearlySavingsPercent: 0,
			pieData: {
				labels: ['Bank Fees', 'Car Related', 'Cash Withdrawel', 'Clothing'],
				datasets: [
					{
						data: [300, 50, 100, 21],
						backgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56"
						],
						hoverBackgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56"
						]
					}
				]
			},
			barData: null,
			barOptions: {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			},
			pieOptions: {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				}
			}
		}
	},
	created() {
		this.transactionService = new TransactionService()
		this.categoryService = new CategoryService()
	},
	mounted() {
		const date = new Date(), y = date.getFullYear(), m = date.getMonth()

		// Monthly
    const firstDay = new Date(y, m, 1)
    // const lastDay = new Date(y, m + 1, 0)
    this.date_range = firstDay
		this.refreshTotals()
	},
	methods: {
		refreshTotals() {
			const y = this.date_range.getFullYear(), m = this.date_range.getMonth()
			// Monthly
			const firstDay = new Date(y, m, 1)
			const lastDay = new Date(y, m + 1, 0)
			this.date_range = firstDay

			this.transactionService.getTransactionsByCategoryDesc('savings', firstDay.toLocaleDateString('en-ca'), lastDay.toLocaleString('en-ca')).then(data => {
				this.actualMonthlySavings = data
				if (data.estimates) {
					this.estimatedMonthlySavingsTotal = data.estimates[0].total
				} else {
					this.estimatedMonthlySavingsTotal = 0
				}
				if (data) {
					let total = 0
					if (data.transactions) {
						for (let transaction of data.transactions) {
							if (transaction.posted) {
								total +=  transaction.cost
							}
						}
					}
					this.actualMonthlySavingsTotal = total
					if (this.estimatedMonthlySavingsTotal > 0 ) {
						this.monthlySavingsPercent = 100 * (this.actualMonthlySavingsTotal / this.estimatedMonthlySavingsTotal)
					} else {
						this.monthlySavingsPercent = this.actualMonthlySavingsTotal
					}
				}
			})

			// Yearly
			const yearFirstDay = new Date(y, 0, 1)
			const yearLastDay = new Date(y, 11, 31)
			this.transactionService.getTransactionsByCategoryDesc('savings', yearFirstDay.toLocaleDateString('en-ca'), yearLastDay.toLocaleString('en-ca')).then(data => {
				this.actualYearlySavings = data
				// this.estimatedYearlySavingsTotal = data.estimate.total
				if (data) {
					let total = 0
					for (let transaction of data.transactions) {
						if (transaction.posted) {
							total +=  transaction.cost
						}
					}
					this.actualYearlySavingsTotal = total
					this.categoryService.getEstimatesByCategory(data.id, yearFirstDay.toLocaleDateString('en-ca'), yearLastDay.toLocaleString('en-ca')).then(data => {
						let total = 0
						for (let estimate of data) {
							total += estimate.total
						}
						this.estimatedYearlySavingsTotal = total
						if (this.estimatedYearlySavingsTotal > 0) {
							this.yearlySavingsPercent = 100 * (this.actualYearlySavingsTotal / this.estimatedYearlySavingsTotal)
						} else {
							this.yearlySavingsPercent = this.actualYearlySavingsTotal

						}
					})
				}
			})

			// Income vs Expense Chart
			this.transactionService.getExpenseVsIncome(this.date_range.getFullYear()).then(data => {
				this.barData = {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
					datasets: [
						{
							label: 'Income',
							backgroundColor: '#00bb7e',
							data: []
						},
						{
							label: 'Expenses',
							backgroundColor: '#FF6384',
							data: []
						}
					]
				}
				var income_set = []
				var expense_set = []
				for (let transaction of data) {
					income_set.push(transaction.income)
					expense_set.push(transaction.expenses)
				}
				// this.barData.datasets[0].data.push(data[0].income)
				this.barData.datasets[0].data = income_set
				this.barData.datasets[1].data = expense_set
			})


		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		},
		goToMonth(value) {
      if (value == 'prev') {
        this.date_range = new Date(this.date_range.getFullYear(), this.date_range.getMonth()-1, 1)
      } else if (value == 'next') {
        this.date_range = new Date(this.date_range.getFullYear(), this.date_range.getMonth()+1, 1)
      }
      this.refreshTotals()
    }
	}
}
</script>
